import React from 'react'

function Links() {
  return (
    <div className='section-container' id='links'>
      <div className="card card-body">
        <h3 className='card-title'>Socials</h3>
        <div className='row'>
          <div className='col'>
            <img className='img-thumbnail rounded-circle' src='images/medium.png' alt='medium'></img>
            <a href='https://medium.com/@melissavaiden'>Medium Blog</a>
          </div>
          <div className='col'>
            <img className='img-thumbnail rounded-circle' src='images/github.png' alt='medium'></img>
            <a href='https://github.com/melissavaiden'>Github</a>
          </div>
          <div className='col'>
            <img className='img-thumbnail rounded-circle' src='images/linkedin.webp' alt='medium'></img>
            <a href='https://www.linkedin.com/in/melissa-vaiden-35b84683/'>Linkedin</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Links