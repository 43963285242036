import React from 'react'

function AboutMe() {
  return (
    <div className='section-container' id='aboutme'>
      <h1 className='about-me-name'>Melissa Vaiden</h1>
      <div className='row align-items-center'>
        <div className='col'>
          <img className='img-fluid rounded-circle' src='images/about_me.JPG' alt='Melissa'></img>
        </div>
        <div className='col card'>
            Meet Melissa Vaiden, a highly motivated and dedicated computer developer who recently graduated from a top coding bootcamp. With a passion for coding and a drive to succeed, Melissa is ready to take on new challenges in the tech industry.
            Originally from South Carolina, Melissa studied culinary arts at The Art Institute of Charleston before attending a rigorous coding bootcamp where she honed her skills in web development, software engineering, and database management. 
            As a recent graduate, Melissa is eager to apply her skills in a professional setting and contribute to innovative projects that make a difference in people's lives. She is proficient in a variety of programming languages, including JavaScript, HTML, CSS, Ruby, and Rails, among others. Melissa is a quick learner who is always seeking to improve her skills and stay up to date on the latest industry trends.
            Melissa is a dedicated problem solver who is committed to delivering high-quality work and exceeding expectations. She is a team player who thrives in collaborative environments and is always eager to learn from others. In her free time, Melissa enjoys walking her dog Cheddar, reading, and exploring new technologies. 
        </div>
      </div>
    </div>

  )
}

export default AboutMe