import React from 'react'

function Footer() {
  return (
    <div className='footer-section'>
        <div className='row'>
            <div className='col'>
                Created by: Melissa Vaiden @ 2023
            </div>
        </div>

    </div>
  )
}

export default Footer