import React from 'react'
import NavBar from './NavBar'
import AboutMe from './AboutMe'
import Projects from './Projects'
import Links from './Links'
import Footer from './Footer'

function HomePage() {
  return (
    <div>
      <NavBar />
      <AboutMe />
      <Projects />
      <Links />
      <Footer />
    </div>
  )
}

export default HomePage