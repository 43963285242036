import HomePage from './components/HomePage';
import './css/App.css';
import { Route, Routes } from 'react-router-dom'
import AboutMe from './components/AboutMe';
import TitlePage from './components/TitlePage';



function App() {
  return (
    <>
      <div className="App">
        <Routes>
          <Route path='/home' element={ <HomePage /> }></Route>
          <Route path='/aboutme' element={ <AboutMe /> }></Route>
          <Route path='/' element={ <TitlePage /> }></Route>
        </Routes>
      </div>
    </>

  );
}

export default App;
