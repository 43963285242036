import React from 'react'
import { Link } from 'react-scroll'


function NavBar() {

  return (
    <div>
        <nav className='navbar'>
            <button className="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent15"
              aria-controls="navbarSupportedContent15" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className='collapse navbar-collapse' id="navbarSupportedContent15">
              <ul className='navbar-nav mr-auto'>
                <li className='nav-item active'>
                  <Link className='nav-link' to='aboutme' spy={true} smooth={true} offset={0} duration={500}>About Me</Link>
                </li>
                <li className='nav-item'>
                  <Link className='nav-link' to='projects' spy={true} smooth={true} offset={0} duration={500}>Projects</Link>
                </li>
                <li className='nav-item'>
                  <Link className='nav-link' to="links" spy={true} smooth={true} offset={0} duration={500}>Socials</Link>
                </li> 
              </ul>
            </div>       
      </nav>
    </div>
  )
}

export default NavBar