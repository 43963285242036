import React from 'react'
import { useNavigate } from 'react-router-dom'



function TitlePage() {
    const navigate = useNavigate();


    function handleTitleClick() {
        navigate('/home')
    }


  return (
    <div>
        <div className='container'>
            <div className='buffer'></div>
            <h1 className='h1' onClick={handleTitleClick}>Melissa Vaiden</h1>
            <div className='buffer'></div>
        </div>
    </div>
  )
}

export default TitlePage