import React from 'react'

function Projects() {
  return (
    <div className='section-container' id='projects'>
      <h1 className='projects-title'>Projects</h1>
      <div className='row align-items-center'>
        <div className='col'>
          <img className='img-thumbnail border border-white' src='../images/js_icon.png'></img>
        </div>
        <div className='col'>
          <img className='img-thumbnail border border-white' src='../images/css_icon.png'></img>
        </div>
        <div className='col'>
          <img className='img-thumbnail border border-white' src='../images/rails_icon.png'></img>
        </div>
        <div className='col'>
          <img className='img-thumbnail border border-white' src='../images/react_icon.png'></img>
        </div>
        <div className='col'>
          <img className='img-thumbnail border border-white' src='../images/html_icon.png'></img>
        </div>
      </div>
      <div className='row'>
        <div className='card col'>
        <img src='../images/homechef_page.png'></img>
          <div className='card-body'>
            <h5 className='card-title'>Home Chef</h5>
            <h6>Forum where people can post different food dishes for sale to people in their local community.</h6>
            <a href='https://youtu.be/nH610EsA958'>Demo Video</a>
            <br></br>
            <a href='https://github.com/melissavaiden/phase-3-project'>Github Link</a>
          </div>
        </div>
        <div className='card col'>
          <img src='../images/fetch_page.png'></img>
            <div className='card-body'>
              <h5 className='card-title'>Fetch Dating</h5>
              <h6>Dating app for dogs where dog owners can create an account and post their dogs for other users to save to their favorites.</h6>
              <a href='https://youtu.be/RsiH4drM8gQ'>Demo Video</a>
              <br></br>
              <a href='https://github.com/melissavaiden/fetch'>Github Link</a>
            </div>
        </div>
        <div className='card col'>
          <img src='../images/bubble_page.png'></img>
            <div className='card-body'>
              <h5 className='card-title'>Bubble Trivia</h5>
              <h6>Trivia game where users can login/logout and play multiple games from many categories.</h6>
              <a href='https://youtu.be/4xiX4pSZ9aA'>Demo Video</a>
              <br></br>
              <a href="https://github.com/melissavaiden/bubble">Github Link</a>
            </div>        
        </div>
      </div>
    </div>
  )
}

export default Projects